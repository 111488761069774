[data-v-1fa6137a] .avueTree .avue-tree__filter .el-input .el-input__inner {
  height: 28px;
  line-height: 28px;
  font-size: 12px;
}
.avue-tree[data-v-1fa6137a] {
  padding: 14px 12px;
}
[data-v-1fa6137a] .el-tree {
  /* height:100% !important; */
  height: calc(100vh - 237px) !important;
  overflow-y: scroll;
}
.common_tree_handle[data-v-1fa6137a] {
  width: 279px;
  margin-right: 0 !important;
  border-right: 1px solid #cccccc;
}
.moreBtn[data-v-1fa6137a] {
  position: absolute;
  top: 14px;
  right: 9px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  padding: 7px;
}